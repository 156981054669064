'use strict';

// eslint-disable-next-line no-unused-vars
const { func } = require('prop-types');
var keyboardAccessibility = require('./keyboardAccessibility');
var $navBar = $('.navbar-nav');
var $mainMenu = $('.main-menu');
var $pageHeader = $('header');
// eslint-disable-next-line no-unused-vars
var $nav = $('.navbar');
var $headerBanner = $('.header-banner');

var isDesktop = function (element) {
    return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
};

var clearSelection = function (element) {
    var $ele = $(element);
    $ele.closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $ele.closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $ele.closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $ele.closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    if (!isDesktop(element)) {
        $ele.closest('li').detach();
    }
};

var quickLinksSlider = function ($quickLinks) {
    if ($quickLinks.is(':visible') && $quickLinks.hasClass('slick-slider')) {
        $quickLinks.slick('unslick');
    }

    $quickLinks.slick({
        variableWidth: true,
        arrows: true,
        infinite: true
    });
    $quickLinks.closest('.dropdown-menu').css('padding-bottom', $quickLinks.outerHeight());
};

var renderMobileCategories = function ($parent) {
    var allCategories;
    if ($parent && !$parent.hasClass('sorted-category')) {
        $parent.addClass('sorted-category');
        allCategories = $parent.find('> li.dropdown-item:not(.dropdown-item-off)').first();
        var allCategoriesHtml = allCategories.find('> .dropdown-link').clone();
        $parent.find('> li:not(.cat-banner)').each(function (i) {
            var $dropDown = $(this);
            if ($dropDown.hasClass('dropdown-item-off')) {
                $dropDown.removeClass('dropdown').find('.dropdown-link').removeClass('dropdown-toggle').removeAttr('data-toggle');
                $dropDown.append($dropDown.find('.dropdown-menu .dropdown-link').clone());
                $dropDown.find('.dropdown-menu').remove();
                if ($dropDown.find('.dropdown-link:not(.dropdown-sub-title):not(.see-all-category)').length > (i === 0 ? 2 : $parent.data('viewcount'))) {
                    var viewMoreLink = $dropDown.find('.dropdown-sub-title').clone();
                    viewMoreLink.html($parent.data('viewmore')).removeClass('dropdown-sub-title dropdown-sub-title-anchor text-uppercase').addClass('see-all-category');
                    $dropDown.append(viewMoreLink);
                }
                var removeLinks = $dropDown.find('.dropdown-link:not(.dropdown-sub-title):not(.see-all-category)').splice((i === 0 ? 2 : $parent.data('viewcount')), $dropDown.find('.dropdown-link').length);
                $.each(removeLinks, function () {
                    $(this).remove();
                });
            } else if ($dropDown.is(allCategories)) {
                allCategories.addClass('dropdown-item-off dropdown-item-all-cat').removeClass('dropdown-item dropdown');
                $dropDown.html('<span class="dropdown-link dropdown-sub-title text-uppercase view-all-categories">' + $parent.data('thecategories') + '</span>');
                $dropDown.append(allCategoriesHtml);
            } else if (!$dropDown.is(allCategories)) {
                allCategories.append($dropDown.find('> .dropdown-link').clone());
                $dropDown.remove();
            }
        });
        allCategories.find('.dropdown-link').removeClass('dropdown-toggle').removeAttr('data-toggle');

        if ($parent.find('.dropdown-item-all-cat').length > 0) {
            var seeAllLink = $parent.prev('.dropdown-link').clone();
            seeAllLink.html($parent.data('seewhole')).removeClass('dropdown-toggle').removeAttr('data-toggle').addClass('see-all-category');
            $parent.find('.dropdown-item-all-cat').append(seeAllLink);
        }
    }
};

var renderDesktopCategories = function ($parent) {
    if ($parent.find('.dropdown-item-off').length && !$parent.hasClass('cat-sync')) {
        $parent.addClass('cat-sync');
        var $li = $parent.find('.dropdown-item-off .dropdown-link').clone();
        $parent.find('.dropdown-item-off').remove();
        $parent.append('<li class="dropdown-item-off desktop-cat-list row m-0"><div class="col level3-column"></div><div class="col level3-column"></div><div class="col level3-column"></div></li>');
        var count = 0;
        var columnCount = 0;
        var maxItemsPerColumn = 13;
        $.each($li, function () {
            if (count >= maxItemsPerColumn) {
                columnCount++;
                count = 0;
            }

            $parent.find('.desktop-cat-list').find('.level3-column').eq(columnCount).append($(this).clone());
            count++;
        });

        var columns = $parent.find('.desktop-cat-list .level3-column');
        var filledColumns = columns.filter(function () {
            return $(this).children().length > 0;
        }).length;

        columns.each(function () {
            $(this).css('width', (100 / filledColumns) + '%');
        });
    }
};

var adjustHeaderBannerOnScroll = function () {
    var scrollPos = $(window).scrollTop();
    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');

    if (scrollPos === 0 && !headerBannerStatus || headerBannerStatus < 0) {
        $headerBanner.removeClass('d-none');
    } else if (scrollPos > $headerBanner.height()) {
        $headerBanner.addClass('d-none');
    }
};

var headerMenuEvents = function () {
    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $headerBanner.addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $headerBanner.removeClass('d-none');
    }

    $(window).on('scroll', function () {
        if ($('#sg-navbar-collapse').attr('aria-hidden') == 'true' || typeof $('#sg-navbar-collapse').attr('aria-hidden') === 'undefined') {
            adjustHeaderBannerOnScroll();
        }
    });

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        });

    $('.dropdown:not(.disabled):not(.country-dropdown) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                if ($(this).hasClass('desktop-toggle')) {
                    return true;
                }
                e.preventDefault();
                $('.modal-background').show();
                // copy parent element into current UL
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                closeMenu.find('.navbar-mobile-logo').remove();
                $('<div class="back"></div>').insertBefore(closeMenu.find('.close-button'));
                $(link).insertBefore(closeMenu.find('.close-button'));
                $(this).parent().children('.dropdown-menu')
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false');
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                if ($(this).parent().parent().hasClass('cat-level-2') && $(this).parent().parent().find('.dropdown-item-slick').length && !$(this).parent().children('.dropdown-menu').find('.quickaccess-slick').length) {
                    $(this).parent().children('.dropdown-menu').append('<li class="quickaccess-slider">' + $(this).parent().parent().find('.dropdown-item-slick')
                        .html() + '</li>');
                    quickLinksSlider($(this).parent().children('.dropdown-menu').find('.quickaccess-slick'));
                }
            }
            return true;
        })
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        var $li = $(this);
                        $li.find('.show').each(function () {
                            clearSelection(this);
                        });
                        if ($li.hasClass('show')) {
                            $li.removeClass('show');
                            $li.children('ul.dropdown-menu').removeClass('show');
                            $li.children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                var $el = $(eventElement);
                var $dropdownMenu = $el.siblings('.dropdown-menu');
                // need to close all the dropdowns that are not direct parent of current dropdown
                $el.parent().addClass('show');
                $dropdownMenu.addClass('show');
                $el.attr('aria-expanded', 'true');
                if ($dropdownMenu) {
                    if ($dropdownMenu.hasClass('cat-level-2')) {
                        renderDesktopCategories($dropdownMenu.find('.cat-level-3.show'));
                    } else if ($dropdownMenu.hasClass('cat-level-3')) {
                        $dropdownMenu.parent().siblings('.cat-banner.image-category-desktop').removeClass('d-xl-flex');
                        renderDesktopCategories($dropdownMenu);
                    }
                }
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).siblings('.cat-banner.image-category-desktop').first().addClass('d-xl-flex');
                $(this).children('.nav-link').attr('aria-expanded', 'false');
            }
        });
    $('.navbar').on('click', '.close-button-main', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();
        $mainMenu.attr('aria-hidden', 'true');
        $mainMenu.siblings().attr('aria-hidden', 'false');
        $pageHeader.siblings().attr('aria-hidden', 'false');
    });
    $('.close-button.close-button-main').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        adjustHeaderBannerOnScroll();
        $mainMenu.attr('aria-hidden', 'true');
        $mainMenu.siblings().attr('aria-hidden', 'false');
        $pageHeader.siblings().attr('aria-hidden', 'false');
    });

    $navBar.on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $navBar.on('click', '.close-button', function (e) {
        e.preventDefault();
        $navBar.find('.top-category').detach();
        $navBar.find('.nav-menu').detach();
        $navBar.find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');
        $mainMenu.siblings().attr('aria-hidden', 'false');
        $pageHeader.siblings().attr('aria-hidden', 'false');
        $('.modal-background').hide();
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $mainMenu.toggleClass('in');
        $('.modal-background').show();

        $headerBanner.addClass('d-none');
        $mainMenu.removeClass('d-none');
        $mainMenu.attr('aria-hidden', 'false');
        $mainMenu.siblings().attr('aria-hidden', 'true');
        $pageHeader.siblings().attr('aria-hidden', 'true');
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        });

    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });
    $('body').on('click', '#myaccount', function () {
        // eslint-disable-next-line no-restricted-globals
        event.preventDefault();
    });

    $('.cat-level-3').each(function () {
        var $parent = $(this);
        renderMobileCategories($parent);
    });

    if (isDesktop('.cat-level-2')) {
        $('.cat-level-2').each(function () {
            var $li = $(this).find('> li:not(.dropdown-item-slick):not(.cat-banner)').splice(10, $(this).find('> li:not(.dropdown-item-slick):not(.cat-banner)').length);
            $.each($li, function () {
                $(this).addClass('d-none');
            });
        });
    }

    $('header .nav-item.dropdown').on('mouseenter', function () {
        if (isDesktop(this)) {
            $('header').addClass('menu-hover-on');
            $('head').append('<style>.menu-hover-on+#maincontent:before{top: ' + $('#maincontent').offset().top + 'px}</style>');
        }
    }).on('mouseleave', function () {
        if (isDesktop(this)) {
            $('header').removeClass('menu-hover-on');
        }
    });
};

var loadSubCategories = function () {
    if ($('.main-menu').length > 0) {
        $.ajax({
            url: $('.main-menu').data('action'),
            type: 'GET',
            dataType: 'HTML',
            success: function (data) {
                $('.main-menu').html(data);
                headerMenuEvents();
            },
            error: function () {
                headerMenuEvents();
            }
        });
    }
};

module.exports = loadSubCategories;

'use strict';

var base = require('core/product/base');
var focusHelper = require('core/components/focus');
var attributesLogic = require('../product/attributes');
var toastUtil = require('../utils/toastPopup');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
                + 'fade show" role="alert">'
                + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
                + '<span aria-hidden="true">&times;</span>'
                + '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> '
                + '<div class="col-12 text-center"> '
                + '<h1>' + data.resources.emptyCartMsg + '</h1> '
                + '</div> '
                + '</div>');
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty().removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.shippingMessage);
    $('.tax-total').empty().append(data.totals.totalTax);
    if (data.clubPrice) {
        $('.remiseclubDisplay').empty().append(data.clubPrice.formatted);
        $('.club-advantage-total').empty().append('- ' + data.clubPrice.formatted);
        $('.club-advantage-value').empty().append(data.clubPrice.formatted);
    }
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- '
            + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        var UUID = '.uuid-' + item.UUID;
        var priceToDisplay = item.orc_is_gift_card ? item.priceTotal.price : item.renderedPrice;
        const isMiniCart = $('.minicart-prd-info').length > 0;

        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
            updateCouponErrorMsg(data); // update the coupon error message
        }

        $(UUID + ' .unit-price').empty().append(priceToDisplay);

        if (!isMiniCart && item.renderedPromotions) {
            $(UUID + ' .product-cart-promotions').empty().append('<div class="lineItem-tile-promotions-item "></div>');
            $(UUID + ' .lineItem-tile-promotions-item').append(`<p class="mb-0 promo-price">${item.priceTotal.unitPrice.formatted}</p>`);
            $(UUID + ' .lineItem-tile-promotions-item').append(item.renderedPromotions);

            $(UUID + ' .standard-list:nth-child(1)').addClass('strike-through');
        } else {
            $(UUID + ' .product-cart-promotions').empty();

            $(UUID + ' .standard-list:nth-child(1)').removeClass('.strike-through');
        }

        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(priceToDisplay);
        $('.item-total-' + item.UUID).empty().append(item.orc_is_gift_card ? priceToDisplay : item.priceTotal.renderedPrice);
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
        + 'fade show" role="alert">'
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * re-renders the coupon error messages
 * @param {Object} data - cart data
 */
function updateCouponErrorMsg(data) {
    if (data.totals && data.totals.discounts) {
        var coupons = data.totals.discounts;
        if (coupons.length > 0) {
            var couponNotAppliedMsg = '';
            for (var i = 0; i < coupons.length; i++) {
                if (coupons[i].applied === false) {
                    couponNotAppliedMsg += coupons[i].couponCode + ' ' + data.resources.couponError + ' <br/>';
                }
            }
            $('.coupon-error-message').empty().append(couponNotAppliedMsg);
        }
    }
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);
            var editProductModal = $('#editProductModal');
            editProductModal.find('.modal-body').empty();
            editProductModal.find('.modal-body').html(parsedHtml.body);
            editProductModal.find('.modal-footer').html(parsedHtml.footer);
            editProductModal.find('.modal-header .close .sr-only').text(data.closeButtonText);
            editProductModal.find('.enter-message').text(data.enterDialogMessage);
            editProductModal.modal('show');
            $('body').trigger('editproductmodal:ready');
            editProductModal.find('.product-attributes-section div[data-attr="orc_size"] .circle-attribute')
                .removeClass('circle-unselected').addClass('circle-selected').text(data.product.variationAttributes[1].displayValue);
            editProductModal.find('.attribute-values-section div[data-attr="orc_size"] .circle-attribute').each(function () {
                var $this = $(this);
                var $selectedSize = $this.find('.attribute-value').text();
                if ($selectedSize === data.product.variationAttributes[1].displayValue) {
                    $this.removeClass('circle-unselected').addClass('circle-selected');
                }
            });
            editProductModal.find('.availability').empty();
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Display the "Product added to the cart" message
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    // show add to cart toast
    if ($('.quick-view-add-to-cart-messages').length === 0) {
        if ($('.cart-page').length !== 0) {
            $('.cart-page').append('<div class="quick-view-add-to-cart-messages"></div>');
        }
    }
    if ($('.quick-view-add-to-basket-alert').length === 0) {
        $('.quick-view-add-to-cart-messages').append(
            '<div class="quick-view-add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );
    }

    setTimeout(function () {
        $('.quick-view-add-to-cart-messages').remove();
    }, 5000);
}

/**
 * Display the "Add Club Card product to cart" button
 * @param {string} cart - cart element
 */
function handleClubCardUpdate(cart) {
    // if the cart has a club card
    if (cart.hasClubCardProduct || $('.club-advantage').hasClass('club-member')) {
        $('.club-card-cta').removeClass('d-md-block');
        $('.club-card-cta-sticky').removeClass('d-block');
        $('.club-advantage').removeClass('d-none');
    } else if (cart.numItems === 0) { // if the cart is empty
        $('.club-card-cta').removeClass('d-md-block'); // hide add-club-card the button
        $('.club-card-cta-sticky').removeClass('d-block');
    } else {
        $('.club-card-cta').addClass('d-md-block');
        $('.club-card-cta-sticky').addClass('d-block');
        $('.club-advantage').addClass('d-none');
        $('.sticky-cart-totals .club-advantage').addClass('d-none');
    }
    stickyClubCardCTA();
}

/**
 * Function to adjust the position of a sticky element above another sticky element.
 * @param {jQuery} $aboveSticky - The jQuery element to be positioned above the target element.
 * @param {jQuery} $targetSticky - The jQuery element to use as a reference for positioning.
 */
function stickyClubCardCTA() {
    const $clubCardCtaSticky = $('.club-card-cta-sticky');
    const $checkoutContinue = $('.checkout-continue');

    /**
    * Adjusts the sticky position of the club card CTA
    */
    function adjustStickyPosition() {
        const checkoutContinueHeight = $checkoutContinue.height();
        $clubCardCtaSticky.css('bottom', checkoutContinueHeight - 15 + 'px');
    }

    adjustStickyPosition();

    $(window).on('resize', adjustStickyPosition);
}

/**
 * Function to display a coupon alert by temporarily removing and then restoring a CSS class.
 */
function displayCouponAlert() {
    const couponAlert = $('.coupon-alert');
    const clubCardCta = $('.club-card-cta');
    const couponAlertSticky = $('.coupon-alert-sticky');
    const clubCardCtaSticky = $('.club-card-cta-sticky');
    const continueCheckout = $('.checkout-continue');

    couponAlert.removeClass('d-md-none');

    if (clubCardCtaSticky.hasClass('d-block')) {
        const totalHeight = continueCheckout.height() + (clubCardCtaSticky.height() - 45);
        couponAlertSticky.css('bottom', totalHeight + 'px');
    } else {
        const continueCheckoutHeight = continueCheckout.height() - 20;
        couponAlertSticky.css('bottom', continueCheckoutHeight + 'px');
    }

    if (clubCardCta.hasClass('d-md-block')) {
        const totalHeight = continueCheckout.height() + (clubCardCta.height() - 10);
        couponAlert.css('top', totalHeight + 'px');
    } else {
        const continueCheckoutHeight = continueCheckout.height() + 15;
        couponAlert.css('top', continueCheckoutHeight + 'px');
    }

    couponAlertSticky.removeClass('d-none');

    setTimeout(function () {
        couponAlert.addClass('d-md-none');
        couponAlertSticky.addClass('d-none');
    }, 5000);
}

/**
 * Get the height of the element with the class 'checkout-continue' and set it as the margin-bottom of the element with the ID 'maincontent'.
 * This function assumes that jQuery is used to select and manipulate the elements.
 *
 * @function
 * @name getSetHeight
 * @returns {void}
 */
function getSetHeight() {
    if (window.innerWidth <= 543 && $('.checkout-continue').length > 0) {
        var stickyHeight = $('.checkout-continue').height();

        $('#maincontent').css('margin-bottom', stickyHeight + 'px');
    }
}

/**
* Process the action for the given overlay
* @param {HTMLElement} overlay - the target overlay element
* @param {string} action - the action to be performed (move or remove)
*/
function processOverlayAction(overlay, action) {
    let $overlay = $(overlay);

    setTimeout(function () {
        let preventAction = $overlay.hasClass('prevent-action');

        if (!preventAction) {
            if (action == 'move') {
                $overlay.find('.move').trigger('click');
            } else if (action == 'remove') {
                $overlay.find('.remove-product-btn').trigger('click');
            }
        }
    }, 3000);
}

module.exports = function () {
    $(document).ready(function () {
        $('body').trigger('cart:init');

        stickyClubCardCTA();

        getSetHeight();
    });

    $('body').unbind().on('click', '.remove-product-btn', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');
        $('body').trigger('cart:removeProductLineItem', { pid: productID, action: actionUrl, uuid: uuid });
    });

    // eslint-disable-next-line no-unused-vars
    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var maxItemsNbCustPref = $('.cart-page-card__variations').data('maxnb-items') ? $('.cart-page-card__variations').data('maxnb-items') : 10;
        var quantity = $(this).val() <= maxItemsNbCustPref ? $(this).val() : maxItemsNbCustPref;
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid,
            // eslint-disable-next-line no-undef
            pagetype: window.GTMConstants.ECOMM_PAGE_TYPE_CART
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.cart').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCouponErrorMsg(data); // update the coupon error message when the product quantity changes
                updateApproachingDiscounts(data.approachingDiscounts);
                updateCartTotals(data);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', 'button.add-club-to-cart-button', function () {
        var productID = $(this).parent().data('pid');
        var quantity = $(this).parent().data('qty'); // 1
        var url = $(this).parent().data('action');
        var uuid = $(this).parent().data('uuid');

        var form = {
            uuid: uuid,
            pid: productID,
            quantity: quantity
        };

        $(this).parents('.cart').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'POST',
            context: this,
            data: form,
            dataType: 'json',
            success: function (data) {
                $.spinner().stop();
                $('.coupons-and-promos').empty().append(data.cart.totals.discountsHtml);
                updateCartTotals(data.cart);
                updateApproachingDiscounts(data.cart.approachingDiscounts);
                updateProductDetails(data, uuid);
                data.cart.hasClubCardProduct = true;

                validateBasket(data.cart);

                handlePostCartAdd(data);
                $('body').trigger('cart:update', data.cart);

                handleClubCardUpdate(data.cart);

                if ($('.cart-page').length > 0) {
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                }
            },
            error: function (err) {
                $.spinner().stop();
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                }
                $.spinner().stop();
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCouponErrorMsg(data); // update the coupon error message when the shipping method updates
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.empty-promo-field', function () {
        $('.promo-code-form ').removeClass('is-invalid');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        $('.coupon-code-field').val('');
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field:visible').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form:visible');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form ').addClass('is-invalid');
                    $('.promo-code-form .form-control').addClass('is-invalid').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCouponErrorMsg(data); // update the coupon error message when submitting a coupon
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                    $('.coupon-code-field').val('');
                    displayCouponAlert();
                }

                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateCouponErrorMsg(data); // update/empty the coupon error message when the user delete the coupon
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit, .minicart .product-edit .edit', function (e) {
        e.preventDefault();
        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        var editProductModal = $('#editProductModal');
        editProductModal.siblings().attr('aria-hidden', 'true');
        editProductModal.find('.close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available'));
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCouponErrorMsg(data); // update the coupon error message when the product updates in the cart
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update', data);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('cart:removeProductLineItem', function (event, params) {
        var productID = params.pid;
        var url = params.action;
        var uuid = params.uuid;
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.cart').empty().append('<div class="container cart-empty">'
                        + '<div class="row">'
                        + '    <div class="col-12 d-flex align-items-center flex-column">'
                        + '        <i class="icon icon-pictoToteBag"></i>'
                        + '        <span class=" text-muted">' + data.basket.resources.emptyCartMsg + '</span>'
                        + '    </div>'
                        + '</div>'
                        + '</div>');
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty().removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                    $('.totals').addClass('emptycart-totals');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    if (data.basket.numItems == 1 && data.basket.items[0].orc_isClubCard) {
                        data.basket.hasClubCardProduct = true;
                        let clubCardProduct = data.basket.items[0];
                        let actionUrl = $('.club-card-item .remove-product-btn').data('action');
                        $('body').trigger('cart:removeProductLineItem', { pid: clubCardProduct.id, action: actionUrl, uuid: clubCardProduct.UUID });
                    }
                    handleClubCardUpdate(data.basket);
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCouponErrorMsg(data); // update the coupon error message when the user deletes a product from the cart
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }

                $('body').trigger('cart:update', data);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    /**
     * move items from Cart to wishlist
     * returns json object with success or error message
     */
    $('body').on('click', '.line-item-overlay .move', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var pid = $(this).data('pid');
        var uuid = $(this).data('uuid');
        var action = $(this).data('action');
        var optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        var optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            // eslint-disable-next-line no-unused-vars
            success: function (data) {
                $('body').trigger('cart:removeProductLineItem', { pid: pid, action: action, uuid: uuid });
                toastUtil.displayToastPopup('.popine-success');
            },
            // eslint-disable-next-line no-unused-vars
            error: function (err) {

            }
        });
    });

    $('body').on('click', '.promo-code .card .card-header button', function () {
        var ariaControls = $(this).attr('aria-controls');
        var ariaExpanded = $(this).attr('aria-expanded');
        $(ariaControls).toggle();
        $(this).attr('aria-expanded', !JSON.parse(ariaExpanded));
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();

    // product recommendation carousel init
    const recommendationUtils = require('../components/recommendations');
    recommendationUtils.initRecommandationLogic();
    recommendationUtils.initProductRecommendationSlick();

    $('body').on('editproductmodal:ready', function () {
        $('.alert-incompatible-shipping-methods').html($('#incompatible-shipping-methods-message-holder').html());
        const $carousel = $('.quick-view-carousel-area .detail-slider-nav.detail-desktop-images.quickview-carousel').not('.slick-initialized');
        $carousel.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            focusOnSelect: true,
            infinite: false,
            arrows: true
        });
    });

    $('.checkout-navbar-back button, .continue-shopping-wrapper a').click(function (e) {
        e.preventDefault();

        var encodedUrl = $(this).attr('data-cbo');
        var backButtonUrl = decodeURIComponent(window.atob(encodedUrl));
        window.location.href = backButtonUrl;
    });

    $('body').on('product:afterAddToCart', (e, data) => {
        var $cartProductsWrapper = $('.js-update-products-cards');

        if ($cartProductsWrapper.length && data.cartProductsTemplate && data.cartProductsTemplate.length) {
            $cartProductsWrapper.html(data.cartProductsTemplate); // update product line items

            $('.coupons-and-promos').empty().append(data.cart.totals.discountsHtml); // update promotions
            updateCouponErrorMsg(data.cart); // update the coupon error message when the product added to cart
            updateApproachingDiscounts(data.cart.approachingDiscounts); // if there is approaching discount, update the section
            updateCartTotals(data.cart); // update prices
            validateBasket(data.cart); // basket validation
            handleClubCardUpdate(data.cart); // handles club values
            handlePostCartAdd(data); // handles add to cart messages
            $('body').trigger('cart:update', data.cart); // gtm update
        } else if (data.cartProductsTemplate && data.cartProductsTemplate.length && ($('.cart-empty').length || $(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length)) {
            window.location.reload();
        }
    });

    $('body').on('click', '.quantity-button', function () {
        const $this = $(this);
        const isIncrease = $this.hasClass('quantity-add');
        const $input = $this.closest('.quantity-form').find('.custom-quantity');
        const $lineItem = $this.closest('.product-info');
        let oldValue = Number($input.val());
        let min = Number($input.attr('min'));
        let max = Number($input.attr('max'));
        let newVal = 1;

        newVal = isIncrease ? oldValue + 1 : oldValue - 1;
        newVal = (newVal < min || newVal > max) ? oldValue : newVal;

        if (oldValue < 2 && !isIncrease) {
            const $overlay = $lineItem.find('.line-item-overlay');
            $overlay.removeClass('d-none move-to-wishlist').addClass('delete-from-cart');
            processOverlayAction($overlay, 'remove');
        } else {
            $input.val(newVal);
            $input.trigger('change');
        }
    });

    $('body').on('click', '.cart-button-swatch-wrapper .move', function (e) {
        e.preventDefault();
        const $lineItem = $(this).closest('.product-info');
        const $overlay = $lineItem.find('.line-item-overlay');
        $overlay.removeClass('d-none delete-from-cart prevent-action').addClass('move-to-wishlist');
        processOverlayAction($overlay, 'move');
    });

    $('body').on('click', '.cart-button-swatch-wrapper .remove', function (e) {
        e.preventDefault();
        const $lineItem = $(this).closest('.product-info');
        const $overlay = $lineItem.find('.line-item-overlay');
        $overlay.removeClass('d-none move-to-wishlist prevent-action').addClass('delete-from-cart');

        processOverlayAction($overlay, 'remove');
    });

    $('body').on('click', '.line-item-overlay .close-overlay', function (e) {
        e.preventDefault();
        let $overlay = $(this).closest('.line-item-overlay');
        $overlay.addClass('d-none').addClass('prevent-action');
    });
};

$(document).on('click', '.product-attributes-section .attribute.toggle', attributesLogic.toggleAttribute);
$(document).on('click', '.attribute-values .circle-unselected', attributesLogic.selectAttributeValue);
